const io = require('socket.io-client')

let socket

const state = {
  secret: 'RVYp5LPPnwQmMMEWGI5JsPw89Lrq7FWkHs6juXWOHzv6iLJQbRSmU7Bq3ISmyLZ',
  PubSubConnected: false,
  PubSubAuthenticated: false
}

let url = (process.env.NODE_ENV === 'production' ? 'https://pokethon.net'  : 'http://staging.pokethon.org') // : 'http://localhost:3000')

socket = io.connect(url)

socket.on('connect', () => {
  PubSub.authenticate(state.secret)
  state.PubSubConnected = true
})

socket.on('disconnect', () => {
  state.PubSubConnected = false
  state.PubSubAuthenticated = false
  console.error('[PubSub] Disconnected')
})


let preloadSubscriptions = []
let subscriptions = []

const PubSub = {
  authenticate (secret) {
    socket.emit('authentication', { secret })
    socket.on('authenticated', () => {
      state.PubSubAuthenticated = true
      console.log('[PubSub] Authenticated to '+url)

      preloadSubscriptions.forEach(({ topic, cb }) => {
        PubSub.subscribe(topic, cb)
      })
      preloadSubscriptions = []
    })
  },
  broadcast (topic, data = null) {
    if (state.PubSubAuthenticated && state.PubSubConnected && socket.emit) {
      socket.emit('broadcast', { topic, data })
    }
  },
  subscribe (topic, cb) {
    if (state.PubSubAuthenticated && state.PubSubConnected && socket.on) {
      socket.on(topic, cb)
      subscriptions.push(topic)
    } else {
      preloadSubscriptions.push({ topic, cb })
    }
  },
  publish (topic, data = null) {
    if (state.PubSubAuthenticated && state.PubSubConnected && socket.emit) {
      socket.emit(topic, data)
    }
  },
  unsubscribeAll () {
    subscriptions.forEach(topic => socket.removeAllListeners)
    subscriptions = []
  }
}

export default PubSub
